import { ThunkDispatch } from 'redux-thunk';
import { produce } from 'immer';
import {
  printerRead,
  printerCheckStatus,
  printerRegister,
  printerUnregister,
  printerCreate,
  printerDeviceUpdate,
  printerDelete,
  Printer,
  PrinterCheckStatusResponse
  // printerGenerateToken,
  // printerReadDeviceState,
  // printerTriggerUpdate,
} from '../../../apis/allevi-api-wrapper';

import { PrinterState } from '../reducers/printer';

import { logoutOnInvalidToken } from '../util/auth';
import { RootState } from '../reducers';
import { getAlleviAdapterState } from '../../../apis/allevi-adapter-wrapper';
import { logoutUser } from './userActions';

// Set printer sorting
export const setPrinterSort = (sortBy: PrinterState['sortBy']) =>
  ({
    type: 'SET_PRINTER_SORT',
    sortBy
  } as const);

export const setPrinterSearchString = (searchString: string) =>
  ({
    type: 'SET_PRINTER_SEARCH_STRING',
    searchString
  } as const);

// Get all printers
const getAllPrintersAction = () =>
  ({
    type: 'GET_ALL_PRINTERS'
  } as const);

const getAllPrintersSuccess = (printers: Printer[]) =>
  ({
    type: 'GET_ALL_PRINTERS_SUCCESS',
    printers
  } as const);

const getAllPrintersFailure = (statusCode: number, message: string) =>
  ({
    type: 'GET_ALL_PRINTERS_FAILURE',
    statusCode,
    message
  } as const);

export function getAllPrinters(skip = 0, limit = 1000, sort: Record<string, number> = {}) {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(getAllPrintersAction());

    return printerRead({}, { skip, limit, sort })
      .then(e => {
        if (e.statusCode === 200 && e.printer) {
          dispatch(getAllPrintersSuccess(e.printer));

          return { success: true, printers: e.printer } as const;
        }
        dispatch(getAllPrintersFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(getAllPrintersFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Check status of printers from list
const checkPrinterStatusAction = (printerList: readonly string[], getDeviceData: boolean) =>
  ({
    type: 'CHECK_PRINTER_STATUS',
    printerList,
    getDeviceData
  } as const);

const checkPrinterStatusSuccess = (printerList: PrinterCheckStatusResponse['printer']) =>
  ({
    type: 'CHECK_PRINTER_STATUS_SUCCESS',
    printerList
  } as const);

const checkPrinterStatusFailure = (statusCode: number, message: string, code?: string) =>
  ({
    type: 'CHECK_PRINTER_STATUS_FAILURE',
    statusCode,
    message,
    code
  } as const);

export function checkPrinterStatus(printerList: readonly string[], getDeviceData = false) {
  return async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    dispatch(checkPrinterStatusAction(printerList, getDeviceData));

    return printerCheckStatus(printerList, getDeviceData)
      .then(async e => {
        if (e.statusCode === 200 && e.printer) {
          let modifiedPrinterStates: Readonly<
            Record<
              string,
              {
                status: Printer['status'];
                lastState: Printer['lastState'];
                cloudStatus?: Printer['status'];
              }
            >
          > = e.printer;
          // check if cloud checkStatus contains acPrinter
          const acSerialNumber = getState().adapter.serialNumber;
          if (acSerialNumber && e.printer[acSerialNumber] !== undefined) {
            try {
              const stateResult = await getAlleviAdapterState();

              modifiedPrinterStates = produce(modifiedPrinterStates, draft => {
                draft[acSerialNumber] = {
                  status: stateResult.state!.status === 'STANDBY' ? 'STANDBY' : 'ONLINE',
                  lastState: stateResult.state!.status,
                  cloudStatus: draft[acSerialNumber].status
                };
              });
            } catch (err) {
              modifiedPrinterStates = produce(modifiedPrinterStates, draft => {
                draft[acSerialNumber] = {
                  status: 'ERROR',
                  //@ts-expect-error
                  lastState: undefined
                };
              });
              console.log(err);
            }
          }

          dispatch(checkPrinterStatusSuccess(modifiedPrinterStates));
          return { printerList: modifiedPrinterStates, success: true } as const;
        }
        dispatch(checkPrinterStatusFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(checkPrinterStatusFailure(e.statusCode, e.message, e.code));
        if (e.code === 'UNAUTHORIZED_ACCESS_TOKEN_ERROR') {
          logoutUser();
        }
        return { success: false } as const;
      });
  };
}

// Register printer
const registerPrinterAction = (serial: string) =>
  ({
    type: 'REGISTER_PRINTER',
    serial
  } as const);

const registerPrinterSuccess = () =>
  ({
    type: 'REGISTER_PRINTER_SUCCESS'
  } as const);

const registerPrinterFailure = (statusCode: number, message: string) =>
  ({
    type: 'REGISTER_PRINTER_FAILURE',
    statusCode,
    message
  } as const);

export function registerPrinter(serial: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(registerPrinterAction(serial));

    return printerRegister(serial)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(registerPrinterSuccess());
          return { success: true } as const;
        }
        dispatch(registerPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(registerPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Unregister printer
const unregisterPrinterAction = (serial: string) =>
  ({
    type: 'UNREGISTER_PRINTER',
    serial
  } as const);

const unregisterPrinterSuccess = () =>
  ({
    type: 'UNREGISTER_PRINTER_SUCCESS'
  } as const);

const unregisterPrinterFailure = (statusCode: number, message: string) =>
  ({
    type: 'UNREGISTER_PRINTER_FAILURE',
    statusCode,
    message
  } as const);

export function unregisterPrinter(serial: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(unregisterPrinterAction(serial));

    return printerUnregister(serial)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(unregisterPrinterSuccess());
          return { success: true } as const;
        }
        dispatch(unregisterPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(unregisterPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Create printer
const createPrinterAction = (
  name: string,
  serial: string,
  modelNumber: number,
  buildNumber: string,
  region: string,
  dbOnly: boolean
) =>
  ({
    type: 'CREATE_PRINTER',
    name,
    serial,
    modelNumber,
    buildNumber,
    region,
    dbOnly
  } as const);

const createPrinterSuccess = () =>
  ({
    type: 'CREATE_PRINTER_SUCCESS'
  } as const);

const createPrinterFailure = (statusCode: number, message: string) =>
  ({
    type: 'CREATE_PRINTER_FAILURE',
    statusCode,
    message
  } as const);

export function createPrinter(
  name: string,
  serial: string,
  modelNumber: number,
  buildNumber: string,
  region: string,
  dbOnly = false
) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(createPrinterAction(name, serial, modelNumber, buildNumber, region, dbOnly));

    let extruders: string[] = [];
    if (modelNumber && modelNumber > 0) {
      //@ts-expect-error
      extruders = [...Array(modelNumber).keys()].map(() => 'SWAPPABLE');
    }

    return printerCreate(
      'DEFAULT',
      name,
      serial,
      { modelNumber, buildNumber },
      //@ts-expect-error
      { ...extruders },
      { region },
      !dbOnly,
      !dbOnly // Don't create entries on GCP and Balena if virtual or offline
    )
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(createPrinterSuccess());
          return { success: true } as const;
        }
        dispatch(createPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(createPrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Delete printer
const deletePrinterAction = (serial: string) =>
  ({
    type: 'DELETE_PRINTER',
    serial
  } as const);

const deletePrinterSuccess = () =>
  ({
    type: 'DELETE_PRINTER_SUCCESS'
  } as const);

const deletePrinterFailure = (statusCode: number, message: string) =>
  ({
    type: 'DELETE_PRINTER_FAILURE',
    statusCode,
    message
  } as const);

export function deletePrinter(serial: string) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(deletePrinterAction(serial));

    return printerDelete({ serialNumber: serial })
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(deletePrinterSuccess());
          return { success: true } as const;
        }
        dispatch(deletePrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(deletePrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Trigger printer update
const updatePrinterAction = (serial: string, updateRelease: boolean, updateOS: boolean) =>
  ({
    type: 'UPDATE_PRINTER',
    serial,
    updateRelease,
    updateOS
  } as const);

const updatePrinterSuccess = (data: TSFixMe) =>
  ({
    type: 'UPDATE_PRINTER_SUCCESS',
    data
  } as const);

const updatePrinterFailure = (statusCode: number, message: string) =>
  ({
    type: 'UPDATE_PRINTER_FAILURE',
    statusCode,
    message
  } as const);

export function updatePrinter(serial: string, updateRelease = true, updateOS = false) {
  return (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(updatePrinterAction(serial, updateRelease, updateOS));

    return printerDeviceUpdate(serial, updateRelease, updateOS)
      .then(e => {
        if (e.statusCode === 200) {
          dispatch(updatePrinterSuccess(e.data));
          return { success: true } as const;
        }
        dispatch(updatePrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      })
      .catch(e => {
        logoutOnInvalidToken(e);

        dispatch(updatePrinterFailure(e.statusCode, e.message));
        return { success: false } as const;
      });
  };
}

// Generate printer token
// const generatePrinterTokenAction = serial => ({
//   type: 'GENERATE_PRINTER_TOKEN',
//   serial,
// });
//
// const generatePrinterTokenSuccess = token => ({
//   type: 'GENERATE_PRINTER_TOKEN_SUCCESS',
//   token,
// });
//
// const generatePrinterTokenFailure = (statusCode:number, message:string) => ({
//   type: 'GENERATE_PRINTER_TOKEN_FAILURE',
//   statusCode,
//   message,
// });
//
// export function generatePrinterToken(serial) {
//   return (dispatch:ThunkDispatch<any,any,any>) => {
//     dispatch(generatePrinterTokenAction(serial));
//
//     return printerGenerateToken(serial)
//       .then(e => {
//         if (e.statusCode === 200 && e.data && e.data.token) {
//           dispatch(generatePrinterTokenSuccess(e.data.token));
//           return { success: true } as const;
//         }
//         dispatch(generatePrinterTokenFailure(e.statusCode, e.message));
//         return { success: false } as const;
//       })
//       .catch(e => {
//         dispatch(generatePrinterTokenFailure(e.statusCode, e.message));
//         return { success: false } as const;
//       });
//   };
// }

// Read device state
// const getPrinterStateAction = serial => ({
//   type: 'GET_PRINTER_STATE',
//   serial,
// });
//
// const getPrinterStateSuccess = data => ({
//   type: 'GET_PRINTER_STATE_SUCCESS',
//   data,
// });
//
// const getPrinterStateFailure = (statusCode:number, message:string) => ({
//   type: 'GET_PRINTER_STATE_FAILURE',
//   statusCode,
//   message,
// });
//
// export function getPrinterState(serial) {
//   return (dispatch:ThunkDispatch<any,any,any>) => {
//     dispatch(getPrinterStateAction(serial));
//
//     return printerReadDeviceState(serial)
//       .then(e => {
//         if (e.statusCode === 200 && e.data && e.data.printers) {
//           dispatch(getPrinterStateSuccess(e.data.printers));
//           return { success: true } as const;
//         }
//         dispatch(getPrinterStateFailure(e.statusCode, e.message));
//         return { success: false } as const;
//       })
//       .catch(e => {
//         dispatch(getPrinterStateFailure(e.statusCode, e.message));
//         return { success: false } as const;
//       });
//   };
// }
