import axios from 'axios';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import PouchDB from 'pouchdb-browser';
import { ThunkDispatch } from 'redux-thunk';
import { getAlleviAdapterState, subscribeToAlleviAdapterState } from '../allevi-adapter-wrapper';

interface CouchDBConfig {
  url: string;
}
interface LocalDB {
  printers: PouchDB.Database<GetLastPrinterStateResponse>;
  slicerNotifications: PouchDB.Database;
  fileNotifications: PouchDB.Database;
}

let tokenRefreshInterval: TSFixMe;
let firebaseToken: string | undefined;
let firebaseConfig: TSFixMe;
let couchDBConfig: CouchDBConfig | undefined;
const onPremDB = window.hostEnvironment && window.hostEnvironment === 'ON_PREM';
let app: firebase.app.App;
let db: firebase.firestore.Firestore | LocalDB | undefined;
let localDb: LocalDB | undefined;
let actions: Record<string, string>;

const unsubscribes = {
  notifications: undefined as (() => void) | undefined,
  printer: undefined as (() => void) | PouchDB.Replication.Replication<{}> | undefined,
  slicerNotifications: undefined as (() => void) | PouchDB.Replication.Replication<{}> | undefined,
  taskNotifications: {} as Record<string, () => void>
};

function hasCancelMethod(v: any): v is { cancel(): Promise<void> } {
  return v && v.cancel && typeof v.cancel === 'function';
}

// Set auth token
export async function setAuthToken(token: string): Promise<void> {
  if (onPremDB) {
    console.log('Using on-prem DB, no auth token required');
    return;
  }

  firebaseToken = token;
}

// Set firebase config
export async function setFirebaseConfig(config: TSFixMe): Promise<void> {
  firebaseConfig = config;
}

// Set CoucbDB config
export async function setCouchDBConfig(config: CouchDBConfig): Promise<void> {
  couchDBConfig = config;
}

// Refresh auth token
export async function refreshToken(): Promise<void> {
  if (onPremDB) {
    console.log('Using on-prem DB, no token refresh required');
    return;
  }

  console.log('Refreshing DB auth token...');
  const newToken = await firebase.auth().currentUser!.getIdToken(true);

  if (newToken) {
    console.log('Successfully refreshed DB auth token');
    setAuthToken(newToken);
  } else {
    console.log('DB auth token refresh failed!');
  }
}

// Initialize comm
export async function initialize(
  callback: ThunkDispatch<any, any, any>,
  commActions: Record<string, string>
): Promise<void> {
  if (!callback || typeof callback !== 'function') {
    console.error('No callback specified. Cannot init comm wrapper.');
    return;
  }

  if (commActions && typeof commActions === 'object') actions = commActions;
  if (onPremDB) {
    // CouchDB for offline
    console.log('Using on-prem DB');

    if (!couchDBConfig) {
      console.log('Local DB config is not set, attempting to fetch from window');

      if (window.couchDBConfig) {
        couchDBConfig = window.couchDBConfig;
      } else {
        throw new Error('Local DB config is not set and could not be retrieved from window');
      }
    }

    try {
      if (!db || !localDb) {
        db = {
          printers: new PouchDB(`${couchDBConfig!.url}/printers`),
          slicerNotifications: new PouchDB(`${couchDBConfig!.url}/slicer_notifications`),
          fileNotifications: new PouchDB(`${couchDBConfig!.url}/file_notifications`)
        };

        localDb = {
          printers: new PouchDB('printers'),
          slicerNotifications: new PouchDB('slicer_notifications'),
          fileNotifications: new PouchDB('file_notifications')
        };

        callback({
          type: actions.onConnectToDB
        });
      } else {
        console.log('DB connection already initialized');
      }
    } catch (error) {
      if (actions && actions.onDisconnectFromDB) {
        callback({
          type: actions.onDisconnectFromDB,
          status: false
        });
      }

      throw new Error(`Cannot initialize DB connection: ${error}`);
    }
  } else {
    // Firebase for online
    try {
      app = firebase.app();
      db = firebase.firestore(app);
      console.log('DB connection already initialized');
      return;
    } catch (e) {
      if (!firebaseToken) {
        throw new Error('Cannot initialize DB connection. Token is not set');
      }

      if (!firebaseConfig) {
        console.log('DB config is not set. Attempting to fetch config from server');

        const response = await axios('/__/firebase/init.json');
        firebaseConfig = await response.data;

        if (!firebaseConfig) throw new Error('DB config is not set and could not be retrieved from server');
      }

      try {
        if (!firebase.apps.length) {
          app = firebase.initializeApp(firebaseConfig);
        } else {
          console.log('Firebase app already initialized');
        }
      } catch (error) {
        if (actions && actions.onDisconnectFromDB) {
          callback({
            type: actions.onDisconnectFromDB,
            status: false
          });
        }

        clearInterval(tokenRefreshInterval);

        throw new Error(`Cannot initialize comm wrapper: ${error}`);
      }

      await firebase
        .auth()
        .signInWithCustomToken(firebaseToken)
        .then(() => {
          callback({
            type: actions.onConnectToDB
          });

          db = firebase.firestore();

          // Refresh token every 15 min
          tokenRefreshInterval = setInterval(() => refreshToken(), 15 * 60 * 1000);
        })
        .catch(error => {
          if (actions && actions.onDisconnectFromDB) {
            callback({
              type: actions.onDisconnectFromDB,
              status: false
            });
          }

          clearInterval(tokenRefreshInterval);

          throw new Error(`Cannot initialize DB connection: ${error}`);
        });
    }
  }
}

// Get latest printer state
interface GetLastPrinterStateResponse {
  readonly lastUpdate: number;
  readonly state: {
    readonly extruders: {
      readonly connected: boolean;
      readonly calibrated: boolean;
    }[];
  };
}
export async function getLastPrinterState(
  callback: ThunkDispatch<any, any, any>,
  commActions: Record<string, string>,
  serialNumber: string
): Promise<GetLastPrinterStateResponse> {
  if (commActions && typeof commActions === 'object') actions = commActions;

  if (!serialNumber) {
    console.log('No serial number specified. Cannot get printer state');
    return undefined as never;
  }

  if (!db) {
    console.log('DB connection not initialized. Attempting init');
    await initialize(callback, commActions);
  }

  callback({
    type: actions.onGetLastPrinterState,
    printer: serialNumber
  });

  // On-prem handler
  if (onPremDB) {
    try {
      const printerData = await (db as LocalDB).printers.get<GetLastPrinterStateResponse>(serialNumber);

      callback({
        type: actions.onGetLastPrinterStateSuccess,
        printer: serialNumber,
        lastUpdate: printerData.lastUpdate ? printerData.lastUpdate : 0,
        state: printerData.state ? printerData.state : {}
      });

      return printerData;
    } catch (error) {
      console.log(`Error getting data from printer ${serialNumber}: ${error}`);

      if (actions && actions.onGetLastPrinterStateFailure) {
        callback({
          type: actions.onGetLastPrinterStateFailure,
          printer: serialNumber
        });
      }
    }
  }

  // Firebase handler
  const handlePrinterData = (printer: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
    if (!printer.exists) {
      console.log(`Printer not found in DB: ${serialNumber}`);
    } else {
      const printerData = printer.data()!;

      callback({
        type: actions.onGetLastPrinterStateSuccess,
        printer: serialNumber,
        lastUpdate: printerData.lastUpdate ? printerData.lastUpdate : 0,
        state: printerData.state ? printerData.state : {}
      });
    }
  };

  const handlePrinterError = (error: any) => {
    console.log(`Error getting data from printer ${serialNumber}: ${error}`);

    if (actions && actions.onGetLastPrinterStateFailure) {
      callback({
        type: actions.onGetLastPrinterStateFailure,
        printer: serialNumber
      });
    }
  };

  try {
    const printerUpdate = await (
      (db as firebase.firestore.Firestore).collection(
        'printers'
      ) as firebase.firestore.CollectionReference<GetLastPrinterStateResponse>
    )
      .doc(serialNumber)
      .get();
    handlePrinterData(printerUpdate);
    return printerUpdate.data()!;
  } catch (error) {
    handlePrinterError(error);
    return undefined as never;
  }
}

// Connect to a printer
export async function connectToPrinter(
  callback: ThunkDispatch<any, any, any>,
  printerConnection: 'useWifi' | 'useAlleviClient',
  commActions: Record<string, string>,
  serialNumber: string
): Promise<void> {
  if (commActions && typeof commActions === 'object') actions = commActions;

  if (!serialNumber) {
    console.log('No serial number specified. Cannot connect to printer');
    return;
  }

  if (!db) {
    console.log('DB connection not initialized. Attempting init');
    await initialize(callback, commActions);
  }

  let useAlleviClient = false;
  if (printerConnection === 'useAlleviClient') {
    try {
      const stateResult = await getAlleviAdapterState();
      if (serialNumber === stateResult.state.serialNumber) {
        useAlleviClient = true;
      }
    } catch (e) {
      console.log(e);
    }
  }

  callback({
    type: actions.onConnectToPrinter,
    printer: serialNumber
  });

  // On-prem handler
  if (onPremDB) {
    try {
      if (hasCancelMethod(unsubscribes.printer)) await unsubscribes.printer.cancel();

      // Get last state
      unsubscribes.printer = PouchDB.replicate((db as LocalDB).printers, (localDb as LocalDB).printers, {
        doc_ids: [serialNumber],
        live: true,
        retry: true
      }).on('change', async info => {
        let printerData: PouchDB.Core.ExistingDocument<GetLastPrinterStateResponse> = undefined as never;
        if (info.docs.length > 0) {
          printerData = info.docs[0];
        }

        callback({
          type: actions.onMessage,
          printer: serialNumber,
          lastUpdate: printerData!.lastUpdate ? printerData.lastUpdate : 0,
          state: printerData!.state ? printerData.state : {}
        });
      });
    } catch (error) {
      console.log(`Error getting data from printer ${serialNumber}: ${error}`);

      if (hasCancelMethod(unsubscribes.printer)) await unsubscribes.printer.cancel();

      if (actions && actions.onDisconnectFromPrinter) {
        callback({
          type: actions.onDisconnectFromPrinter,
          printer: serialNumber
        });
      }
    }
  } else if (useAlleviClient) {
    unsubscribes.printer = subscribeToAlleviAdapterState(serialNumber, parsedData =>
      callback({
        type: actions.onMessage,
        printer: serialNumber,
        lastUpdate: parsedData.lastUpdate ? parsedData.lastUpdate : Date.now(),
        state: parsedData.state ? parsedData.state : {}
      })
    );
  } else {
    // Firestore handler
    const handlePrinterData = (printer: firebase.firestore.DocumentSnapshot<GetLastPrinterStateResponse>) => {
      if (!printer.exists) {
        console.log(`Printer not found in DB: ${serialNumber}`);
      } else {
        const printerData = printer.data()!;

        callback({
          type: actions.onMessage,
          printer: serialNumber,
          lastUpdate: printerData.lastUpdate ? printerData.lastUpdate : 0,
          state: printerData.state ? printerData.state : {}
        });
      }
    };

    const handlePrinterError = (error: any) => {
      console.log(`Error getting data from printer ${serialNumber}: ${error}`);

      if (actions && actions.onDisconnectFromPrinter) {
        callback({
          type: actions.onDisconnectFromPrinter,
          printer: serialNumber
        });
      }
    };

    if (typeof unsubscribes.printer === 'function') await unsubscribes.printer();

    // Set up handler for printer data updates
    unsubscribes.printer = (
      (db as firebase.firestore.Firestore).collection(
        'printers'
      ) as firebase.firestore.CollectionReference<GetLastPrinterStateResponse>
    )
      .doc(serialNumber)
      .onSnapshot(
        printerUpdate => {
          handlePrinterData(printerUpdate);
        },
        error => {
          handlePrinterError(error);
        }
      );
  }
}

// Disconnect from a printer
export async function disconnectFromPrinter(
  callback: ThunkDispatch<any, any, any>,
  commActions: Record<string, string>,
  serialNumber: string
): Promise<void> {
  if (commActions && typeof commActions === 'object') actions = commActions;

  if (actions && actions.onDisconnectFromPrinter) {
    callback({
      type: actions.onDisconnectFromPrinter,
      printer: serialNumber
    });
  }

  // On-prem version
  if (onPremDB) {
    if (hasCancelMethod(unsubscribes.printer)) await unsubscribes.printer.cancel();

    return;
  }

  if (typeof unsubscribes.printer === 'function') unsubscribes.printer();
}

// Connect notification listener
export async function connectNotificationListener(
  callback: ThunkDispatch<any, any, any>,
  commActions: Record<string, string>,
  userId: string
): Promise<void> {
  if (commActions && typeof commActions === 'object') actions = commActions;

  if (!userId) {
    console.log('No user ID specified. Cannot connect notification listener');
    return;
  }

  if (!db) {
    console.log('DB connection not initialized. Attempting init');
    await initialize(callback, commActions);
  }

  callback({
    type: actions.onConnectNotificationListener,
    userId
  });

  // Set up slicer notifications
  if (onPremDB) {
    // On-prem version
    // TODO
    try {
      if (hasCancelMethod(unsubscribes.slicerNotifications)) await unsubscribes.slicerNotifications.cancel();

      // Get last state
      unsubscribes.slicerNotifications = PouchDB.replicate(
        (db as LocalDB).slicerNotifications,
        localDb!.slicerNotifications,
        {
          doc_ids: [userId],
          live: true,
          retry: true
        }
      ).on('change', async info => {
        let notificationsData;
        if (info.docs.length > 0) {
          notificationsData = info.docs[0];
        }

        callback({
          type: actions.onSlicerNotification,
          userId,
          notification: notificationsData
        });
      });
    } catch (error) {
      console.log(`Error getting slicer notifications for user ID ${userId}: ${error}`);

      if (hasCancelMethod(unsubscribes.slicerNotifications)) await unsubscribes.slicerNotifications.cancel();

      if (actions && actions.onDisconnectNotificationListener) {
        callback({
          type: actions.onDisconnectNotificationListener,
          userId
        });
      }
    }
  } else {
    // Firestore version
    if (typeof unsubscribes.slicerNotifications === 'function') await unsubscribes.slicerNotifications();

    unsubscribes.notifications = (db as firebase.firestore.Firestore)
      .collection('slicerNotifications')
      .where(firebase.firestore.FieldPath.documentId(), '==', userId)
      .onSnapshot(
        notificationSnapshot => {
          if (notificationSnapshot.empty) {
            console.log(`User ID not found in slicer notifications DB: ${userId}`);
          } else {
            // Only send notification messages when data is modified
            notificationSnapshot.docChanges().forEach(change => {
              if (change.type === 'modified') {
                const notificationsData = change.doc.data();

                callback({
                  type: actions.onSlicerNotification,
                  userId,
                  notification: notificationsData
                });
              }
            });
          }
        },
        error => {
          console.log(`Error getting slicer notifications for user ID ${userId}: ${error}`);

          if (actions && actions.onDisconnectNotificationListener) {
            callback({
              type: actions.onDisconnectNotificationListener,
              userId
            });
          }
        }
      );
  }
}

// Disconnect notification listener
export async function disconnectNotificationListener(
  callback: ThunkDispatch<any, any, any>,
  commActions: Record<string, string>,
  userId: string
): Promise<void> {
  if (commActions && typeof commActions === 'object') actions = commActions;

  if (actions && actions.onDisconnectNotificationListener) {
    callback({
      type: actions.onDisconnectNotificationListener,
      userId
    });
  }

  // On-prem version
  if (onPremDB) {
    // TODO
    return;
  }

  if (typeof unsubscribes.notifications === 'function') unsubscribes.notifications();
}

// Connect task listener
export async function connectTaskListener(
  callback: ThunkDispatch<any, any, any>,
  commActions: Record<string, string>,
  userId: string,
  teamId: string,
  taskId: string
): Promise<void> {
  if (commActions && typeof commActions === 'object') actions = commActions;

  if (!(userId && teamId && taskId)) {
    console.log('User ID, team ID, or task ID not specified. Cannot connect task listener');
    return;
  }

  callback({
    type: actions.onConnectTaskListener,
    taskId
  });

  // On-prem version
  if (onPremDB) {
    // TODO
    return;
  }

  if (typeof unsubscribes.taskNotifications[taskId] === 'function') await unsubscribes.taskNotifications[taskId]();

  const disconnectTask = () => {
    if (actions && actions.onDisconnectTaskListener) {
      callback({
        type: actions.onDisconnectTaskListener,
        userId,
        teamId,
        taskId
      });
    }
  };

  const teamCollection = (db as firebase.firestore.Firestore).collection(teamId);
  if (!teamCollection) {
    console.log(`Team ID not found in DB: ${teamId}`);
    disconnectTask();
    return;
  }

  const userDoc = teamCollection.doc(userId);
  if (!userDoc) {
    console.log(`User ID not found in DB: ${userId}`);
    disconnectTask();
    return;
  }

  const printJobNotifications = userDoc.collection('printJobNotifications');
  if (!printJobNotifications) {
    console.log(`printJobNotifications not found in DB: ${teamId}/${userId}`);
    disconnectTask();
    return;
  }

  const taskDoc = printJobNotifications.doc(taskId);
  if (!taskDoc) {
    console.log(`Task ID not found in DB: ${teamId}/${userId} ${taskId}`);
    disconnectTask();
    return;
  }

  unsubscribes.taskNotifications[taskId] = taskDoc.onSnapshot(
    taskSnapshot => {
      //@ts-expect-error
      if (taskSnapshot.empty) {
        console.log(`Task ID not found in team/user/printJobNotifications DB: ${teamId}/${userId} ${taskId}`);
      } else {
        callback({
          type: actions.onTaskNotification,
          taskId,
          notification: taskSnapshot.data()
        });
      }
    },
    (error: any) => {
      console.log(
        `Error getting task notifications for team/user/printJobNotifications: ${teamId}/${userId}: ${error}`
      );

      if (actions && actions.onDisconnectTaskListener) {
        callback({
          type: actions.onDisconnectTaskListener,
          userId,
          teamId,
          taskId
        });
      }
    }
  );
}

// Disconnect task listener
export async function disconnectTaskListener(
  callback: ThunkDispatch<any, any, any>,
  commActions: Record<string, string>,
  userId: string,
  teamId: string,
  taskId: string
): Promise<void> {
  if (commActions && typeof commActions === 'object') actions = commActions;

  if (actions && actions.onDisconnectTaskListener) {
    callback({
      type: actions.onDisconnectTaskListener,
      userId,
      teamId,
      taskId
    });
  }

  // On-prem version
  if (onPremDB) {
    // TODO
    return;
  }

  if (typeof unsubscribes.taskNotifications[taskId] === 'function') unsubscribes.taskNotifications[taskId]();
}
