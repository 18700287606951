import Immutable from 'seamless-immutable';
import { assertType } from '../../../util/assertType';
import {
  moveExtruder,
  removePrintParams,
  setActiveSection,
  setBedTargetTemp,
  setBuildPlate,
  setConnectionHealth,
  setConnectionStatus,
  setCrosslinkingParams,
  setCurrentPrintIndex,
  setExtruderJogStep,
  setExtruderStatus,
  setExtruderTargetPosition,
  setExtruderTargetPressure,
  setExtruderTargetTemp,
  setPrinterData,
  setPrintParams,
  setSelectedExtruder,
  setStatus,
  setWellsToPrint,
  setWorklightStatus,
  toggleBedTempActive,
  toggleExtruderEnabled,
  toggleExtruderPressureActive,
  toggleExtruderTempActive
} from '../actions/deviceActions';
import { assertActionType } from '../util/assertActionType';

export interface ExtruderState {
  readonly id: number;
  readonly connected: boolean;
  readonly enabled: boolean;
  readonly status: undefined | 'down';
  readonly position: readonly [number | undefined, number | undefined, number | undefined];
  readonly targetPosition: readonly [number | undefined, number | undefined, number | undefined];
  readonly tempActive: boolean;
  readonly tempStatus: 0 | 1 | 2 | 3;
  readonly eAxisPosition: 'up' | 'down';
  readonly currentTemp: number | undefined;
  readonly targetTemp: number | undefined;
  readonly extruderActive: boolean;
  readonly currentPressure: number | undefined;
  readonly targetPressure: number | undefined;
  readonly calibrated: boolean;
  readonly pressureActive: boolean;
  readonly type: 'CORE' | 'HT';
}

export interface BedState {
  readonly tempActive: boolean;
  readonly targetTemp: number | undefined;
  readonly currentTemp?: number;
}

export interface PrintParams {
  readonly extruder?: number;
  readonly scale?: number;
  readonly layerHeight?: number;
  readonly speed?: number;
  readonly xOffset?: number;
  readonly yOffset?: number;
  readonly zOffset?: number;
  readonly infillType?: 'None';
  readonly infillDistance?: number;
  readonly infillDirection?: number;
  readonly file?: TSFixMe;
}

export interface PrintState {
  readonly file: TSFixMe;
  readonly filename: string;
  readonly fileId: string;
  readonly fileType: string;
  readonly checksum: string;
  readonly preset: string;
  readonly scale: number;
  readonly layerHeight: number;
  readonly speed: number;
  readonly travelSpeed: number;
  readonly xOffset: number;
  readonly yOffset: number;
  readonly zOffset: number;
  readonly parameters: PrintParams;
}

export interface CrossLinkingState {
  readonly duringPrint: {
    readonly enabled: boolean;
    readonly type: number;
    readonly intensity: number;
    readonly duration: number;
    readonly frequency: number;
  };
  readonly afterPrint: {
    readonly enabled: boolean;
    readonly type: number;
    readonly intensity: number;
    readonly duration: number;
  };
  readonly manual: {
    readonly enabled: boolean;
    readonly type: number;
    readonly intensity: number;
  };
  readonly advanced: {
    readonly autoscroll: boolean;
    readonly logContents: string;
  };
}

export interface DeviceState {
  readonly connectionStatus: 'connected' | 'disconnected' | 'connecting';
  readonly connectionHealth: 'disconnected' | 'good' | 'poor';
  readonly lastPrinterMessage: number | undefined;
  readonly name: string;
  readonly modelNumber: number;
  readonly serialNumber: string;
  readonly status: 'idle' | 'processing' | 'printing' | 'temping' | 'paused' | 'autocalibrating';
  readonly activeSection:
    | 'status'
    | 'current-print'
    | 'build-plate'
    | 'extruders'
    | 'crosslinking'
    | 'print-bed'
    | 'advanced';
  readonly selectedExtruder: number;
  readonly extruderJogStep: number;
  readonly log: string;
  readonly capabilities: readonly [];

  readonly deviceState: {};

  readonly worklight: boolean;

  readonly currentPrintIndex: number;
  readonly wellsToPrint: readonly [];

  readonly bed: BedState;

  readonly extruders: readonly ExtruderState[];

  readonly print: readonly PrintState[];
  readonly buildPlate: {
    readonly type: string;
    readonly wellCount: number;
    readonly manufacturer: string;
    readonly model: string;
  };
  readonly crosslinking: CrossLinkingState;
}

const defaultPrintParams = Immutable<PrintParams>({
  extruder: 0,
  scale: 1,
  layerHeight: 0.2,
  speed: 6,
  xOffset: 0,
  yOffset: 0,
  zOffset: 0,
  infillType: 'None',
  infillDistance: 1,
  infillDirection: 0
});

const initialState = Immutable<DeviceState>({
  connectionStatus: 'disconnected',
  connectionHealth: 'disconnected',
  lastPrinterMessage: undefined,
  name: '',
  //@ts-expect-error
  modelNumber: '',
  serialNumber: '',
  status: 'idle',
  activeSection: 'status',
  selectedExtruder: 0,
  extruderJogStep: 1,
  log: '',
  capabilities: [],

  deviceState: {},

  worklight: false,

  currentPrintIndex: 0,
  wellsToPrint: [],

  bed: {
    tempActive: false,
    targetTemp: undefined
  },

  extruders: [
    // {
    //   id: 0,
    //   connected: true,
    //   enabled: true,
    //   status: "down",
    //   position: [60, 30, 10],
    //   targetPosition: [60, 30, 10],
    //   tempActive: true,
    //   currentTemp: 35,
    //   targetTemp: 35,
    //   extruderActive: false,
    //   currentPressure: 30,
    //   targetPressure: 30,
    //   calibrated: true,
    // },
  ],

  // print: [{
  //   filename: '',
  //   fileId: '',
  //   fileType: '',
  //   checksum: '',
  //   preset: '',
  //   scale: 1,
  //   layerHeight: 0.2,
  //   speed: 10,
  //   travelSpeed: 20,
  //   xOffset: 0,
  //   yOffset: 0,
  //   zOffset: 0,
  // }],

  print: [],

  buildPlate: {
    type: '',
    wellCount: 0,
    manufacturer: '',
    model: ''
  },

  crosslinking: {
    duringPrint: {
      enabled: false,
      type: 0,
      intensity: 0,
      frequency: 1,
      duration: 0
    },

    afterPrint: {
      enabled: false,
      type: 0,
      intensity: 0,
      duration: 0
    },

    manual: {
      enabled: false,
      type: 0,
      intensity: 0
    },

    advanced: {
      autoscroll: true,
      logContents: ''
    }
  }
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'RESET_DEVICE':
      return initialState;

    case 'COMM_DISCONNECT':
      state = state.set('currentPrintIndex', 0);
      state = state.set('wellsToPrint', []);

      return state;

    case 'PRINTER_DISCONNECT':
      state = state.set('connectionStatus', 'disconnected');
      state = state.set('currentPrintIndex', 0);
      state = state.set('wellsToPrint', []);
      state = state.set('print', []);

      return state;

    case 'GET_PRINTER_STATE_SUCCESS':
      if (action.data === undefined) return state;

      return state.updateIn(['deviceState'], p => p.merge(action.data));

    case 'SET_PRINTER_DATA':
      assertActionType(action, setPrinterData);
      if (action.printerData === undefined) return state;

      // Set extruder list if model number changed
      const modelNumber = parseInt(action.printerData.modelNumber as any, 10);
      if (!isNaN(modelNumber) && modelNumber !== state.modelNumber) {
        const extrudersList: ExtruderState[] = Array(modelNumber)
          .fill(undefined)
          .map(
            (e, i) =>
              ({
                id: i,
                connected: false,
                enabled: false,
                calibrated: false,
                type: 'CORE',
                position: [undefined, undefined, undefined],
                targetPosition: [undefined, undefined, undefined],
                eAxisPosition: 'up',
                tempActive: false,
                tempStatus: 0, // 0=off, 1=cooling, 2=heating, 3=fans on
                currentTemp: undefined,
                targetTemp: undefined,
                currentPressure: undefined,
                targetPressure: undefined,
                extruderActive: false,
                pressureActive: false,
                status: undefined
              } as ExtruderState)
          );

        state = state.setIn(['extruders'], extrudersList);
      }

      return state.merge(action.printerData);

    case 'SET_DEVICE_STATUS':
      assertActionType(action, setStatus);
      if (action.status === undefined) return state;

      return state.setIn(['status'], action.status);

    case 'SET_ACTIVE_SECTION':
      assertActionType(action, setActiveSection);
      if (action.section === undefined) return state;

      return state.setIn(['activeSection'], action.section);

    case 'SET_CONNECTION_STATUS':
      assertActionType(action, setConnectionStatus);
      if (action.status === undefined) return state;

      state = state.setIn(['connectionStatus'], action.status);

      if (action.status === 'connected') {
        state = state.setIn(['connectionHealth'], 'good');
      }

      return state;

    case 'SET_CONNECTION_HEALTH':
      assertActionType(action, setConnectionHealth);
      if (action.status === undefined) {
        return state;
      }

      return state.setIn(['connectionHealth'], action.status);

    case 'SET_WORKLIGHT_STATUS':
      assertActionType(action, setWorklightStatus);
      if (action.status === undefined) return state;

      return state.setIn(['worklight'], action.status);

    // Handle socket message
    case 'PRINTER_MESSAGE': {
      assertType<{
        state?: {
          serialNumber: string;
          status?: string;
          Wellplate?: {
            Type?: number;
          };
          crosslinking?: {
            duringPrint?: CrossLinkingState['duringPrint'];
            afterPrint?: CrossLinkingState['afterPrint'];
          };
          bed?: BedState;
          extruders: {
            connected?: boolean;
            enabled?: boolean;
            calibrated?: boolean;
            status?: ExtruderState['status'];
            type?: string;
            Eposition?: number;
            position?: [number, number, number];
            targetPosition?: [number, number, number];
            tempActive?: boolean;
            tempStatus?: ExtruderState['tempStatus'];
            currentTemp?: number;
            targetTemp?: number;
            currentPressure?: number;
            targetPressure?: number;
            extruderActive?: boolean;
          }[];
        };
        lastUpdate?: number;
      }>(action);

      if (action.state === undefined) return state;

      if (action.state.serialNumber !== state.serialNumber) return state;

      if (action.lastUpdate) state = state.setIn(['lastPrinterMessage'], Date.now());

      if (action.state.status) state = state.setIn(['status'], action.state.status.toLowerCase());

      if (action.state.Wellplate) {
        // TEMP
        // Change when firmware stores more wellplate info
        if (action.state.Wellplate.Type !== undefined) {
          let buildPlateType = 'Petri Dish';
          if (action.state.Wellplate.Type === 0) buildPlateType = 'Glass Slide';
          if (action.state.Wellplate.Type > 1) buildPlateType = `${action.state.Wellplate.Type}-Well Plate`;

          state = state.setIn(['buildPlate', 'type'], buildPlateType);
          state = state.setIn(['buildPlate', 'wellCount'], action.state.Wellplate.Type);
        }
      }

      if (action.state.crosslinking) {
        if (action.state.crosslinking.duringPrint) {
          state = state.setIn(['crosslinking', 'duringPrint'], action.state.crosslinking.duringPrint);
        }

        if (action.state.crosslinking.afterPrint) {
          state = state.setIn(['crosslinking', 'afterPrint'], action.state.crosslinking.afterPrint);
        }
      }

      if (action.state.bed) {
        state = state.setIn(['bed'], action.state.bed);
      }

      action.state.extruders.forEach((e, i) => {
        if (e.connected !== undefined) state = state.setIn(['extruders', i, 'connected'], e.connected);

        if (e.enabled !== undefined) state = state.setIn(['extruders', i, 'enabled'], e.enabled);

        if (e.calibrated !== undefined) state = state.setIn(['extruders', i, 'calibrated'], e.calibrated);

        if (e.status !== undefined) state = state.setIn(['extruders', i, 'status'], e.status);

        if (e.type !== undefined) {
          if (parseInt(e.type, 10) === 1000 || parseInt(e.type, 10) === 2000) {
            state = state.setIn(['extruders', i, 'type'], 'CORE');
          } else if (parseInt(e.type, 10) === 4990) {
            state = state.setIn(['extruders', i, 'type'], 'HT');
          }
        }

        if (e.Eposition !== undefined) {
          if (e.Eposition < 1) {
            state = state.setIn(['extruders', i, 'eAxisPosition'], 'down');
          } else if (e.Eposition >= 1) {
            state = state.setIn(['extruders', i, 'eAxisPosition'], 'up');
          }
        }

        if (e.position !== undefined) state = state.setIn(['extruders', i, 'position'], e.position);

        if (e.targetPosition !== undefined) state = state.setIn(['extruders', i, 'targetPosition'], e.targetPosition);

        if (e.tempActive !== undefined) state = state.setIn(['extruders', i, 'tempActive'], e.tempActive);

        if (e.tempStatus !== undefined) state = state.setIn(['extruders', i, 'tempStatus'], e.tempStatus);

        if (e.currentTemp !== undefined) state = state.setIn(['extruders', i, 'currentTemp'], e.currentTemp);

        if (e.targetTemp !== undefined) state = state.setIn(['extruders', i, 'targetTemp'], e.targetTemp);

        if (e.currentPressure !== undefined)
          state = state.setIn(['extruders', i, 'currentPressure'], e.currentPressure);

        if (e.targetPressure !== undefined) state = state.setIn(['extruders', i, 'targetPressure'], e.targetPressure);

        if (e.extruderActive !== undefined) state = state.setIn(['extruders', i, 'extruderActive'], e.extruderActive);
      });

      return state;
    }

    // Current print
    case 'SET_CURRENT_PRINT_INDEX':
      assertActionType(action, setCurrentPrintIndex);
      if (action.index === undefined) return state;

      return state.setIn(['currentPrintIndex'], action.index);

    case 'SET_WELLS_TO_PRINT':
      assertActionType(action, setWellsToPrint);
      if (action.wells === undefined) return state;

      return state.setIn(['wellsToPrint'], action.wells);

    case 'SET_PRINT_PARAMS':
      assertActionType(action, setPrintParams);
      if (action.params === undefined) return state;

      const file = action.params.file;
      //@ts-expect-error
      const params: Partial<PrintParams> = Immutable.without(action.params, 'file');

      if (!state.print[action.printIndex]) {
        state = state.setIn(['print', action.printIndex, 'file'], file);
        state = state.setIn(['print', action.printIndex, 'parameters'], defaultPrintParams);
      }

      return state.updateIn(['print', action.printIndex, 'parameters'], p => p.merge(params));

    case 'REMOVE_PRINT_PARAMS': {
      assertActionType(action, removePrintParams);
      if (action.printIndex === undefined) return state;

      const newPrints = Immutable.asMutable(state.print);
      //@ts-expect-error
      newPrints.splice(action.printIndex, 1);

      return state.setIn(['print'], newPrints);
    }

    // Build plate
    case 'SET_BUILD_PLATE':
      assertActionType(action, setBuildPlate);
      if (action.buildplate === undefined) return state;

      state = state.setIn(['buildPlate', 'type'], action.buildplate);
      state = state.setIn(['buildPlate', 'wellCount'], action.wellCount);

      return state;

    // Extruders
    case 'SET_SELECTED_EXTRUDER':
      assertActionType(action, setSelectedExtruder);
      if (action.selectedExtruder === undefined) return state;

      return state.setIn(['selectedExtruder'], action.selectedExtruder);

    case 'SET_EXTRUDER_JOG_STEP':
      assertActionType(action, setExtruderJogStep);
      if (action.step === undefined) return state;

      return state.setIn(['extruderJogStep'], action.step);

    case 'TOGGLE_EXTRUDER_ENABLED':
      assertActionType(action, toggleExtruderEnabled);
      if (action.extruder === undefined) return state;

      return state.setIn(['extruders', action.extruder, 'enabled'], !state.extruders[action.extruder].enabled);

    case 'SET_EXTRUDER_STATUS':
      assertActionType(action, setExtruderStatus);
      if (action.extruder === undefined || action.status === undefined) return state;

      return state.setIn(['extruders', action.extruder, 'status'], action.status);

    case 'MOVE_EXTRUDER': {
      assertActionType(action, moveExtruder);
      if (action.extruder === undefined || action.axis === undefined || action.delta === undefined) return state;

      let position = { ...state.extruders[action.extruder].targetPosition };
      let axisIndex = ['x', 'y', 'z'].indexOf(action.axis.toLowerCase());
      position[axisIndex]! += action.delta;

      return state.setIn(['extruders', action.extruder, 'targetPosition'], position);
    }

    case 'SET_EXTRUDER_TARGET_POSITION': {
      assertActionType(action, setExtruderTargetPosition);
      if (action.extruder === undefined || action.position === undefined) return state;

      let { position } = action;
      if (!Array.isArray(position)) position = Object.values(position) as [number, number, number];

      return state.setIn(['extruders', action.extruder, 'targetPosition'], position);
    }

    case 'TOGGLE_EXTRUDER_TEMP_ACTIVE':
      assertActionType(action, toggleExtruderTempActive);
      if (action.extruder === undefined) return state;

      return state.setIn(
        ['extruders', action.extruder, 'tempActive'],
        action.override !== undefined ? action.override : !state.extruders[action.extruder].tempActive
      );

    case 'SET_EXTRUDER_TARGET_TEMP':
      assertActionType(action, setExtruderTargetTemp);
      if (action.extruder === undefined || action.temp === undefined) return state;

      return state.setIn(['extruders', action.extruder, 'targetTemp'], action.temp);

    case 'TOGGLE_EXTRUDER_PRESSURE_ACTIVE':
      assertActionType(action, toggleExtruderPressureActive);
      if (action.extruder === undefined) return state;

      return state.setIn(
        ['extruders', action.extruder, 'pressureActive'],
        action.override !== undefined ? action.override : !state.extruders[action.extruder].pressureActive
      );

    case 'SET_EXTRUDER_TARGET_PRESSURE':
      assertActionType(action, setExtruderTargetPressure);
      if (action.extruder === undefined || action.pressure === undefined) return state;

      return state.setIn(['extruders', action.extruder, 'targetPressure'], action.pressure);

    // Crosslinking
    case 'SET_CROSSLINKING_PARAMS':
      assertActionType(action, setCrosslinkingParams);
      if (action.params === undefined) return state;

      return state.update('crosslinking', p => p.merge(action.params, { deep: true }));

    // Bed
    case 'TOGGLE_BED_TEMP_ACTIVE':
      assertActionType(action, toggleBedTempActive);
      return state.setIn(
        ['bed', 'tempActive'],
        action.override !== undefined ? action.override : !state.bed.tempActive
      );

    case 'SET_BED_TARGET_TEMP':
      assertActionType(action, setBedTargetTemp);
      if (action.temp === undefined) return state;

      return state.setIn(['bed', 'targetTemp'], action.temp);

    default:
      return state;
  }
};
