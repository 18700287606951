export default class AlleviError extends Error {
  constructor(code, message, statusCode = 0, ...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AlleviError);
    }
    this.code = code;
    this.message = message;
    this.statusCode = statusCode;
  }
}

// module.exports = {
//   AlleviError,
// };
