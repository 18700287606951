export type Axis = 'x' | 'y' | 'z' | 'e';

const gcode = {
  clearLogs: () => ['G33'],

  cancelPrint: () => ['M2'],

  cancelPrintAndMoveUp: () => ['M2', 'G90', 'G27'],

  pausePrint: () => ['M25'],

  resumePrint: () => ['M24'],

  cancelAutocalibrate: () => ['C9'],

  setExtruder: (e: number) => {
    const extruder = parseInt(e as any, 10);
    if (isNaN(extruder) || extruder < 0) return '';
    return [`T${extruder}`] as const;
  },

  autoCalibrate: (e: number) => {
    const extruder = parseInt(e as any, 10);
    if (Number.isNaN(extruder) || extruder < 0) return '';
    return [`P4 T${extruder}`, 'M400'] as const;
  },

  autoCalibrateAll: () => ['P6', 'M400'],

  setManualCalibration: (e: number, px: number, py: number, pz: number) => {
    const extruder = parseInt(e as any, 10);
    const posX = parseFloat(px as any);
    const posY = parseFloat(py as any);
    const posZ = parseFloat(pz as any);
    if (Number.isNaN(extruder) || extruder < 0 || Number.isNaN(posX) || Number.isNaN(posY) || Number.isNaN(posZ))
      return '';
    return ['G90', `T${extruder}`, `P5 X${posX} Y${posY} Z${posZ}`] as const;
  },

  setPosition: (e: number, px: number, py: number, pz: number) => {
    const extruder = parseInt(e as any, 10);
    const posX = parseFloat(px as any);
    const posY = parseFloat(py as any);
    const posZ = parseFloat(pz as any);
    if (Number.isNaN(extruder) || extruder < 0 || Number.isNaN(posX) || Number.isNaN(posY) || Number.isNaN(posZ))
      return '';
    return ['G90', `T${extruder}`, `G1 X${posX} Y${posY} Z${posZ} F400`] as const;
  },

  moveRelative: (e: number, a: Axis, d: number) => {
    const extruder = parseInt(e as any, 10);
    const axis = a;
    const delta = parseFloat(d as any);
    if (Number.isNaN(extruder) || extruder < 0 || axis === undefined || Number.isNaN(delta)) return '';

    let movement = '';
    switch (axis.toLowerCase()) {
      case 'x':
        movement = `X${delta} Y0 Z0`;
        break;
      case 'y':
        movement = `X0 Y${delta} Z0`;
        break;
      case 'z':
        movement = `X0 Y0 Z${delta}`;
        break;
      default:
        movement = '';
    }

    if (movement !== '') return ['G91', `T${extruder}`, `G1 ${movement} F400`, 'G90'] as const;
    return '';
  },

  moveEAxis: (e: number, p: number) => {
    const extruder = parseInt(e as any, 10);
    const pos = parseFloat(p as any);
    if (Number.isNaN(extruder) || extruder < 0 || pos === undefined || Number.isNaN(pos)) {
      return '';
    }
    return [`T${extruder}`, 'G31', `G1 E${pos}`] as const;
  },

  zeroAxis: (e: number, a: Axis) => {
    const extruder = parseInt(e as any, 10);
    const axis = a;
    if (Number.isNaN(extruder) || extruder < 0 || axis === undefined) return '';

    const axisArray = [];
    if (axis.includes('x')) axisArray.push('X0');
    if (axis.includes('y')) axisArray.push('Y0');
    if (axis.includes('z')) axisArray.push('Z0');

    if (axisArray.length > 0) return ['G90', `T${extruder}`, `G1 ${axisArray.join(' ')} F400`] as const;
    return '';
  },

  homeAxis: (e: number, a: Axis) => {
    const extruder = parseInt(e as any, 10);
    const axis = a;
    if (Number.isNaN(extruder) || extruder < 0 || axis === undefined) return '';

    const axisArray = [];
    if (axis.includes('x')) axisArray.push('X0');
    if (axis.includes('y')) axisArray.push('Y0');
    if (axis.includes('z')) axisArray.push('Z0');
    if (axis.includes('e')) axisArray.push('E0');

    if (axisArray.length > 0) return [`T${extruder}`, `G28 ${axisArray.join(' ')}`] as const;
    return '';
  },

  setEnclosureLEDs: (r: number, g: number, b: number) => {
    const red = parseInt(r as any, 10);
    const green = parseInt(g as any, 10);
    const blue = parseInt(b as any, 10);
    if (Number.isNaN(red) || red < 0 || Number.isNaN(green) || green < 0 || Number.isNaN(blue) || blue < 0) return '';
    return [`M150 R${red} G${green} B${blue}`] as const;
  },

  stopMotors: () => ['M18'],

  setWellPlate: (w: number) => {
    const wellCount = parseInt(w as any, 10);
    if (Number.isNaN(wellCount) || wellCount < 0) {
      return '';
    }

    return [`C101 W0 T${wellCount}`] as const;
  },

  setWellPlateAndCenter: (w: number) => {
    const wellCount = parseInt(w as any, 10);
    if (Number.isNaN(wellCount) || wellCount < 0) return '';
    return [`C100 W0 T${wellCount}`] as const;
  },

  disableTemperatureControl: (e: number) => {
    const extruder = parseInt(e as any, 10);
    if (Number.isNaN(extruder) || extruder < 0) return '';
    return [`M104 T${extruder} S0`] as const;
  },

  setTemperature: (e: number, t: number) => {
    const extruder = parseInt(e as any, 10);
    const temp = parseFloat(t as any);
    if (Number.isNaN(extruder) || Number(extruder) < 0 || Number.isNaN(temp) || Number(temp) < 0 || Number(temp) > 260)
      return '';
    return [`M104 T${extruder} S${temp}`] as const;
  },

  setTemperatureAndWait: (e: number, t: number) => {
    const extruder = parseInt(e as any, 10);
    const temp = parseFloat(t as any);
    if (Number.isNaN(extruder) || Number(extruder) < 0 || Number.isNaN(temp) || Number(temp) < 0 || Number(temp) > 260)
      return '';
    return [`M109 T${extruder} S${temp}`] as const;
  },

  disableBedTemperatureControl: () => ['M140 S0'],

  setBedTemperature: (t: number) => {
    const temp = parseFloat(t as any);
    if (Number.isNaN(temp) || temp < 0 || temp > 60) return '';
    return [`M140 S${temp}`] as const;
  },

  setPressure: (e: number, p: number) => {
    const extruder = parseInt(e as any, 10);
    const pressure = parseFloat(p as any);
    if (Number.isNaN(extruder) || extruder < 0 || Number.isNaN(pressure) || pressure < 0 || pressure > 120) return '';
    return [`C1 T${extruder} S${pressure}`] as const;
  },

  extrudeStart: (e: number) => {
    const extruder = parseInt(e as any, 10);
    if (Number.isNaN(extruder) || extruder < 0) return '';
    return [`T${extruder}`, 'M106'] as const;
  },

  extrudeStop: (e: number) => {
    const extruder = parseInt(e as any, 10);
    if (Number.isNaN(extruder) || extruder < 0) return '';
    return [`T${extruder}`, 'M107'] as const;
  },

  setManualCrosslinking: (t: number, i: number, a: boolean) => {
    const type = parseInt(t as any, 10);
    const intensity = parseFloat(i as any);
    const active = a;
    if (Number.isNaN(type) || type < 0 || type > 1 || Number.isNaN(intensity) || intensity < 0 || intensity > 100)
      return '';

    if (active) {
      return [`M42 L${type} S${intensity}`] as const;
    }
    return [`M42 L${type} S0`] as const;
  },

  disableCrosslinkingDuringPrint: () => ['P2'],

  setCrosslinkingDuringPrint: (i: number, t: number, d: number, f: number) => {
    const intensity = parseFloat(i as any);
    const type = parseInt(t as any, 10);
    const duration = parseFloat(d as any);
    const frequency = parseInt(f as any, 10);
    if (
      Number.isNaN(intensity) ||
      intensity < 0 ||
      Number.isNaN(type) ||
      type < 0 ||
      type > 1 ||
      Number.isNaN(duration) ||
      duration < 0 ||
      Number.isNaN(frequency) ||
      frequency < 0
    )
      return '';
    return [`P2 S${intensity} L${type} D${duration} F${frequency}`] as const;
  },

  disableCrosslinkingAfterPrint: () => ['P3'],

  setCrosslinkingAfterPrint: (i: number, t: number, d: number) => {
    const intensity = parseFloat(i as any);
    const type = parseInt(t as any, 10);
    const duration = parseFloat(d as any);
    if (
      Number.isNaN(intensity) ||
      Number(intensity) < 0 ||
      Number.isNaN(type) ||
      Number(type) < 0 ||
      Number(type) > 1 ||
      Number.isNaN(duration) ||
      Number(duration) < 0
    )
      return '';
    return [`P3 S${intensity} L${type} D${duration}`] as const;
  },

  printFile: (u: string, c: string | undefined = undefined) => {
    const url = u;
    const checksum = c;
    if (!url || url.toString().length <= 0) return '';

    let f1 = `F1 ${url}`;
    if (checksum) f1 = `${f1} ${checksum}`;
    return [f1, 'G90', 'G27'] as const;
  },

  autocalibrateCenterWellplateAndPrintFile: (u: string, wt: number, wi: number, c: string | undefined = undefined) => {
    const url = u;
    const wellType = parseInt(wt as any, 10);
    const wellIndex = parseInt(wi as any, 10);
    const checksum = c;
    if (
      !url ||
      url.toString().length <= 0 ||
      Number.isNaN(wellType) ||
      Number(wellType) < 0 ||
      Number.isNaN(wellIndex) ||
      Number(wellIndex) < 0
    )
      return '';

    let f1 = `F1 ${url}`;
    if (checksum) f1 = `${f1} ${checksum}`;

    return ['P6', `C100 T${wellType} W${wellIndex}`, f1, 'G90', 'G27'] as const;
  },

  printFileInWellplate: (u: string, w: number, c: string | undefined = undefined) => {
    const url = u;
    const wellplate = parseInt(w as any, 10);
    const checksum = c;
    if (!url || url.toString().length <= 0 || Number.isNaN(wellplate) || Number(wellplate) < 0) return '';

    let f2 = `F2 ${url} ${wellplate}`;
    if (checksum) f2 = `${f2} ${checksum}`;

    return [f2, 'G90', 'G27'] as const;
  },

  autocalibrateAndPrintFileInWellplate: (u: string, w: number, c: string | undefined = undefined) => {
    const url = u;
    const wellplate = parseInt(w as any, 10);
    const checksum = c;
    if (!url || url.toString().length <= 0 || Number.isNaN(wellplate) || Number(wellplate) < 0) return '';

    let f2 = `F2 ${url} ${wellplate}`;
    if (checksum) f2 = `${f2} ${checksum}`;

    return ['P6', f2, 'G90', 'G27'] as const;
  },

  centerWellplateAndPrintFile: (u: string, wt: number, wi: number, c: string | undefined = undefined) => {
    const url = u;
    const wellType = parseInt(wt as any, 10);
    const wellIndex = parseInt(wi as any, 10);
    const checksum = c;
    if (
      !url ||
      url.toString().length <= 0 ||
      Number.isNaN(wellType) ||
      Number(wellType) < 0 ||
      Number.isNaN(wellIndex) ||
      Number(wellIndex) < 0
    )
      return '';

    let f1 = `F1 ${url}`;
    if (checksum) f1 = `${f1} ${checksum}`;

    return [`C100 T${wellType} W${wellIndex}`, f1, 'G90', 'G27'] as const;
  },
  disconnectWifi: () => {
    return ['C510 S1'] as const;
  },
  restoreWifi: () => {
    return ['C510 S0'] as const;
  }
};

export default gcode;
